<template>
  <dx-util-popup
    ref="problemFormPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    :height="'auto'"
    :width="resizingWidth"
    @shown="problemFormPopupShown"
    @hidden="problemFormPopupHidden"
  >
    <div v-if="!showBarcode">
      <div class="mb-2">
        <h4 class="text-danger">
          <div class="badge badge-warning badge-glow mx-2">
            ATTENTION
          </div>
          Please create the problem in Inventory > Receive Inventory page if possible
          <p-icon name="bi-exclamation-circle-fill" class="pr-1" />
        </h4>
        This form creates a new problem along with an item in the Shared Batch in Inbound > Shipment Plans.
        If an item already exists in customer inbound shipping plans, please create the problem in Inventory > Receive Inventory page.
      </div>
      <dx-util-form ref="problemFormRef" :form-data="problemForm" :col-count="12" label-location="top">
        <dx-util-item template="companyHeader" :col-span="12" />
        <dx-util-item data-field="accountNo" :col-span="6" editor-type="dxTextBox" :editor-options="accountEditorOptions">
          <dx-util-required-rule message="Please enter an account number" />
        </dx-util-item>
        <dx-util-item data-field="storeId" :col-span="6" editor-type="dxSelectBox" :editor-options="storeEditorOptions" :label="{ text: 'Store'}">
          <dx-util-required-rule message="Please select a store" />
        </dx-util-item>
        <dx-util-item template="productHeader" :col-span="12" />
        <dx-util-item data-field="item.ProductTitle" :col-span="6" editor-type="dxTextBox" :label="{ text: 'Product Title'}">
          <dx-util-required-rule message="Please enter product title" />
        </dx-util-item>
        <dx-util-item data-field="item.Action" :col-span="3" editor-type="dxSelectBox" :editor-options="itemActionEditorOptions" :label="{ text: 'Item Action'}">
          <dx-util-required-rule message="Please select item action" />
        </dx-util-item>
        <dx-util-item data-field="item.Condition" :col-span="3" editor-type="dxSelectBox" :editor-options="conditionEditorOptions" :label="{ text: 'Item Condition'}" />
        <dx-util-item data-field="item.ManufactureOrSupplier" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Supplier / Manufacturer'}" />
        <dx-util-item data-field="item.ProductGroup" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Product Group'}" />
        <dx-util-item data-field="item.Tracking" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Tracking Number'}" />
        <dx-util-item data-field="item.OrderNumber" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Order Number'}" />
        <dx-util-item data-field="item.UPC" :col-span="3" editor-type="dxTextBox" :label="{ text: 'UPC'}">
          <dx-util-pattern-rule :pattern="upcPattern" message="UPC is not valid format. It should be 12 or 13 digits." />
        </dx-util-item>
        <dx-util-item data-field="item.ASIN" :col-span="3" editor-type="dxTextBox" :label="{ text: 'ASIN'}">
          <dx-util-pattern-rule :pattern="asinPattern" message="ASIN should be valid" />
        </dx-util-item>
        <dx-util-item data-field="item.MSKU" :col-span="3" editor-type="dxTextBox" :label="{ text: 'MSKU'}">
          <dx-util-pattern-rule :pattern="mskuPattern" message="MSKU should be valid" />
        </dx-util-item>
        <dx-util-item data-field="item.FNSKU" :col-span="3" editor-type="dxTextBox" :label="{ text: 'FNSKU'}">
          <dx-util-pattern-rule :pattern="fnskuPattern" message="FNKSU should start with the letter 'X' followed by exactly 9 capitalized alphanumeric characters." />
        </dx-util-item>
        <dx-util-item data-field="item.SerialNo" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Serial No'}" />
        <dx-util-item data-field="item.PalletId" :col-span="3" editor-type="dxTextBox" :label="{ text: 'Pallet ID'}" />
        <dx-util-item data-field="item.Cost" :col-span="3" editor-type="dxNumberBox" :editor-options="costEditorOptions" :label="{ text: 'Cost'}" />
        <dx-util-item data-field="item.SalePrice" :col-span="3" editor-type="dxNumberBox" :editor-options="salePriceEditorOptions" :label="{ text: 'Sale Price'}" />
        <dx-util-item template="packingTypeSelection" :col-span="12" />
        <dx-util-item template="problemHeader" :col-span="12" />
        <dx-util-item data-field="problem.problemType" :col-span="4" editor-type="dxSelectBox" :editor-options="problemTypeEditorOptions" :label="{ text: 'Problem Type'}">
          <dx-util-required-rule message="Please select a problem type" />
        </dx-util-item>
        <dx-util-item data-field="problem.damageType" :col-span="4" editor-type="dxSelectBox" :editor-options="damageTypeEditorOptions" :label="{ text: 'Damage Type'}">
          <dx-util-required-rule v-if="!disableDamageTypeSelection" message="Please select a damage type" />
        </dx-util-item>
        <dx-util-item data-field="problem.count" :col-span="4" editor-type="dxNumberBox" :editor-options="countEditorOptions" :label="{ text: 'Count'}">
          <dx-util-required-rule message="Please enter a count greater than 0" />
          <dx-util-range-rule message="Please select a damage type" :min="1" />
        </dx-util-item>
        <dx-util-item data-field="problem.problemStatus" :col-span="4" editor-type="dxSelectBox" :editor-options="problemStatusEditorOptions" :label="{ text: 'Problem Status'}">
          <dx-util-required-rule message="Please select a problem status" />
        </dx-util-item>
        <dx-util-item data-field="problem.problemResolutionType" :col-span="4" editor-type="dxSelectBox" :editor-options="resolutionTypeEditorOptions" :label="{ text: 'Resolution Type'}" />
        <dx-util-item data-field="problem.location" :col-span="4" editor-type="dxTextBox" />
        <dx-util-item data-field="problem.notes" editor-type="dxTextArea" :col-span="6" :label="{ text: 'Notes'}" />
        <template #companyHeader>
          <form-header header="Company Info" />
        </template>
        <template #problemHeader>
          <form-header header="Problem Info" />
        </template>
        <template #productHeader>
          <form-header header="Product Details" />
        </template>
        <template #packingTypeSelection>
          <div>
            <div class="flex-grow-1">
            <div class="d-flex mx-1 my-1 align-items-center">
              <dx-util-radio-group
                v-model="selectedPacking"
                :data-source="packingOptions"
                layout="horizontal"
                display-expr="text"
                value-expr="value"
                class="mr-2"
                @value-changed="onPackingChange"
              />
              <div v-if="selectedPacking !== 'single' " class="d-flex align-items-center">
                <label for="countPerBunde" class="mr-half">Count per B/MP:</label>
                <dx-util-number-box v-model="problemForm.item.countPerBundle" :show-spin-buttons="true" class="mr-1" style="width: 50px;">
                  <dx-util-validator>
                    <dx-util-range-rule :min="2" message="Quantity should be greater than 1" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
            </div>
          </div>
          </div>
        </template>
      </dx-util-form>
      <div class="text-right mt-2">
        <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
        <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-column align-items-center justify-content-center px-0">
        <div class="p-1">
          <div id="printJS-barcode" class="text-center">
            <barcode
              :value="barcodeNumber"
              :options="{ displayValue: true }"
            />
          </div>
          <div class="text-center">
            <dx-util-button text="Print Barcode" type="default" class="px-2" @click="printBarcode" />
          </div>
        </div>
        <div style="max-width:800px">
          <service-folder-file-manager
            v-if="barcodeNumber"
            ref="refServiceFolderFileManager"
            :company-id="problemCompanyId"
            :upload-document-classes="tabs"
            :entity-folder-id="barcodeNumber"
            :upload-document-class="activeClass"
            :service-folder="serviceFolder"
          />
        </div>
        <div class="d-flex p-1 justify-content-end">
        <dx-util-button type="danger" text="Close" class="mr-1" style="min-width:100px" @click="closePopup" />
      </div>
    </div>
    </div>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import conditionTypeEnum, { getConditionTypeEnumList } from '@/enums/conditionTypeEnum'
import { getYesNoEnumList } from '@/enums/yes-no.enum'
import useCurrentUser from '@/libs/app/current-user'
import problemTypeEnum from '@/enums/problemTypeEnum'
import damageTypeEnum from '@/enums/damageTypeEnum'
import problemStatusEnum from '@/enums/problemStatusEnum'
import problemResolutionTypeEnum from '@/enums/problemResolutionTypeEnum'
import PrintJS from 'print-js'
import { useWindowSize } from '@vueuse/core'
import problemManagementService from '@/http/requests/problem-management/problemManagementService'
import ItemActionTypeEnum from '@/enums/itemActionTypeEnum'
// import companyService from '@/http/requests/company/companyService'
// import commonEntityService from '@/http/requests/common/commonEntityService'
import companyService from '@/http/requests/company/companyService'

export default {
  components: {
    'form-header': () => import('./FormHeader.vue'),
    'service-folder-file-manager': () => import('@components/service-folder/ServiceFolderFileManager.vue'),
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      problemFormPopupRef: 'problemFormPopupRef',
      storeList: [],
      showBarcode: false,
      popupTitle: '',
      barcodeNumber: null,
      problemCompanyId: null,
      serviceFolder: 'PROBLEMS',
      tabs: ['Photo', 'ShippingLabel'],
      activeClass: 'Photo',
      actions: [],
      conditions: getConditionTypeEnumList(),
      packingOptions: [
        { text: 'Single', value: 'single' },
        { text: 'Bundle', value: 'bundle' },
        { text: 'Multipack', value: 'multipack' },
      ],
      selectedPacking: 'single',
      yesNo: getYesNoEnumList(),
      defaultItemFormData: {},
      problemForm: {
        accountNo: null,
        storeId: null,
        problem: {
          companyId: null,
          storeId: null,
          warehouseId: null,
          itemId: null,
          count: null,
          openedBy: null,
          location: null,
          warehouseLocation: null,
          problemType: null,
          damageType: 'none',
          problemStatus: problemStatusEnum.open.value,
          problemResolutionType: problemResolutionTypeEnum.notResolvedYet.value,
          fileLink: null,
          resolvedBy: null,
          approvedBy: null,
          tracking: null,
          carrier: null,
          notes: '',
          firstNotes: null,
        },
        item: {
          TrackingNumber: null,
          PalletId: null,
          OrderNumber: null,
          ExpirationDate: null,
          ASIN: null,
          MSKU: null,
          UPC: null,
          FNSKU: null,
          SerialNo: null,
          ProductTitle: null,
          ManufactureOrSupplier: null,
          ProductGroup: null,
          NumberOfUnits: 0,
          Cost: 0,
          SalePrice: 0,
          Fragile: 'NO',
          MultiPack: 'NO',
          Bundle: 'NO',
          CountPerBundle: 0,
          Action: ItemActionTypeEnum.fba.value,
          Condition: conditionTypeEnum.NEW.key,
          Notes: null,
          storeId: null,
        },
      },
      currencyOptions: {
        format: '$ #,##0.##',
      },
      numberOptions: {
        min: 0,
        step: 1,
        showSpinButtons: true,
      },
      isValid: true,
      validationErrors: [],
      isEditting: false,
      isFragile: false,
      upcPattern: /^[0-9]{12,13}/,
      fnskuPattern: /^X[A-Z0-9]{9}/,
      mskuPattern: /^(-?\d{1,40}|.{1,40})$/,
      asinPattern: /^(B[A-Z0-9]{9})|([0-9]{9}[0-9Xx])$/,
      problemTypes: Object.values(problemTypeEnum),
      damageTypes: Object.values(damageTypeEnum),
      problemStatusOptions: Object.values(problemStatusEnum),
      problemResolutionTypes: Object.values(problemResolutionTypeEnum),
    }
  },
  setup() {
    const {
      userFullname, userId, userAccountNo, userWarehouseId,
    } = useCurrentUser()
    const { width: windowWidth } = useWindowSize()
    return {
      userFullname, userId, windowWidth, userAccountNo, userWarehouseId,
    }
  },
  computed: {
    resizingWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '90vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '80vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '50vw'
      }
      return adjustedWidth
    },
    problemFormPopup() {
      return this.$refs.problemFormPopupRef.instance
    },
    disableDamageTypeSelection() {
      return this.problemForm.problem.problemType !== 'damaged'
    },
    problemFormInstance() {
      return this.$refs.problemFormRef.instance
    },
    countEditorOptions() {
      return {
        min: 0,
      }
    },
    accountEditorOptions() {
      return {
        onEnterKey: this.searchAccountNo,
        buttons: [
          {
            name: 'password',
            location: 'after',
            options: {
              icon: 'icon icon-feather-search',
              type: 'default',
              onClick: this.getStoreListByAccountNo(this.problemForm.accountNo),
            },
          },
        ],
      }
    },
    storeEditorOptions() {
      return {
        dataSource: this.storeList,
        displayExpr: 'storeName',
        valueExpr: 'storeId',
      }
    },
    problemTypeEditorOptions() {
      return {
        dataSource: this.problemTypes,
        displayExpr: 'text',
        valueExpr: 'value',
        onSelectionChanged: e => {
          if (e.selectedItem.value !== problemTypeEnum.damaged.value) {
            this.problemForm.problem.damageType = 'none'
          }
        },
      }
    },
    damageTypeEditorOptions() {
      return {
        dataSource: this.damageTypes,
        disabled: this.disableDamageTypeSelection,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    problemStatusEditorOptions() {
      return {
        dataSource: this.problemStatusOptions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    resolutionTypeEditorOptions() {
      return {
        dataSource: this.problemResolutionTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    itemActionEditorOptions() {
      return {
        dataSource: this.actions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    conditionEditorOptions() {
      return {
        dataSource: this.conditions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    costEditorOptions() {
      return {
        min: 0,
        format: '$ #,##0.##',
      }
    },
    salePriceEditorOptions() {
      return {
        min: 0,
        format: '$ #,##0.##',
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.problemFormPopup.show()
      },
    },
  },
  mounted() {
    this.getItemActions()
  },
  methods: {
    problemFormPopupShown() {
      this.popupTitle = 'Create New Problem'
      this.setExistingInfo()
    },
    problemFormPopupHidden() {
      // Clear form when popup is closed
      this.showBarcode = false
      this.resetForm()
    },
    closePopup() {
      this.problemFormPopup.hide()
    },
    searchAccountNo(e) {
      if (e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          const accountNo = e.event.target.value
          this.getStoreListByAccountNo(accountNo)
          e.component.focus()
        }
      }
      if (e.event.keyCode === 27) {
        const accountNo = e.event.target.value
        this.getStoreListByAccountNo(accountNo)
        e.component.focus()
      }
    },
    getStoreListByAccountNo(accountNo) {
      if (accountNo === null || accountNo === undefined || accountNo === '') {
        return
      }
      this.storeList = []
      companyService
        .fetchCompanyAndStoresByAccountNo(accountNo)
        .then(result => {
          if (!result.data?.id) {
            Notify.error('Company not found. you cannot create a problem')
            return
          }
          this.problemForm.problem.companyId = result.data.id
          this.problemForm.item.companyId = result.data.id
          const data = []
          result.data.stores.forEach(element => {
            data.push({
              storeId: element.id,
              storeName: element.name,
            })
          })
          this.storeList = data
        })
    },
    onPackingChange(e) {
      const packing = e.value
      switch (packing) {
        case 'bundle':
          this.problemForm.item.multiPack = 'NO'
          this.problemForm.item.bundle = 'YES'
          break
        case 'multipack':
          this.problemForm.item.multiPack = 'YES'
          this.problemForm.item.bundle = 'NO'
          break
        default:
          this.problemForm.item.multiPack = 'NO'
          this.problemForm.item.bundle = 'NO'
          break
      }
    },
    async handleSubmit() {
      const result = this.$refs.problemFormRef.instance.validate()
      if (this.problemForm.problem.problemType === problemTypeEnum.none.value) {
        Notify.error('Please select a problem type')
        return
      }
      if (this.problemForm.problem.problemType === problemTypeEnum.damaged.value && this.problemForm.problem.damageType === damageTypeEnum.none.value) {
        Notify.error('Please select a damage type')
        return
      }
      if (!this.problemForm.problem.companyId || !this.problemForm.item.companyId) {
        Notify.error('Please type the account number and press enter to find the company ID')
        return
      }
      if (result.isValid) {
        // const data = this.problemForm
        // delete data.accountNo
        this.problemForm.item.NumberOfUnits = this.problemForm.problem.count
        this.problemForm.item.warehouseId = this.problemForm.problem.warehouseId
        this.problemForm.item.storeId = this.problemForm.storeId
        this.problemForm.problem.storeId = this.problemForm.storeId
        const problem = await problemManagementService.createMissingItemProblem(this.problemForm)
        this.barcodeNumber = problem.id
        this.problemCompanyId = problem.companyId
        this.showBarcode = true
        this.$emit('emit-form-saved')
        // this.closePopup()
      }
    },
    getItemActions() {
      Object.keys(this.$enums.ItemActionTypeEnum).map(key => this.actions.push({
        value: this.$enums.ItemActionTypeEnum[key].value,
        text: this.$enums.ItemActionTypeEnum[key].text,
        disabled: false,
      }))
    },
    printBarcode() {
      // this.resetForm()
      PrintJS('printJS-barcode', 'html')
      Notify.success('Barcode is printing.')
    },
    resetForm() {
      this.problemForm.accountNo = null
      this.problemForm.storeId = null

      this.problemForm.problem.companyId = null
      this.problemForm.problem.warehouseId = null
      this.problemForm.problem.itemId = null
      this.problemForm.problem.count = null
      this.problemForm.problem.openedBy = null
      this.problemForm.problem.location = null
      this.problemForm.problem.warehouseLocation = null
      this.problemForm.problem.problemType = null
      this.problemForm.problem.damageType = 'none'
      this.problemForm.problem.problemStatus = problemStatusEnum.open.value
      this.problemForm.problem.problemResolutionType = problemResolutionTypeEnum.notResolvedYet.value
      this.problemForm.problem.fileLink = null
      this.problemForm.problem.resolvedBy = null
      this.problemForm.problem.approvedBy = null
      this.problemForm.problem.tracking = null
      this.problemForm.problem.carrier = null
      this.problemForm.problem.notes = ''
      this.problemForm.problem.firstNotes = null
      this.problemForm.problem.storeId = null

      this.problemForm.item.TrackingNumber = null
      this.problemForm.item.PalletId = null
      this.problemForm.item.OrderNumber = null
      this.problemForm.item.ExpirationDate = null
      this.problemForm.item.ASIN = null
      this.problemForm.item.MSKU = null
      this.problemForm.item.UPC = null
      this.problemForm.item.FNSKU = null
      this.problemForm.item.SerialNo = null
      this.problemForm.item.ProductTitle = null
      this.problemForm.item.ManufactureOrSupplier = null
      this.problemForm.item.ProductGroup = null
      this.problemForm.item.NumberOfUnits = 0
      this.problemForm.item.Cost = 0
      this.problemForm.item.SalePrice = 0
      this.problemForm.item.Fragile = 'NO'
      this.problemForm.item.MultiPack = 'NO'
      this.problemForm.item.Bundle = 'NO'
      this.problemForm.item.CountPerBundle = 0
      this.problemForm.item.Action = ItemActionTypeEnum.fba.value
      this.problemForm.item.Condition = conditionTypeEnum.NEW.key
      this.problemForm.item.Notes = null
      this.problemForm.item.storeId = null
      this.problemForm.item.companyId = null
    },

    setExistingInfo() {
      this.problemForm.problem.openedBy = this.userId
      this.problemForm.problem.warehouseId = this.userWarehouseId
      this.problemForm.problem.problemStatus = problemStatusEnum.open.value
      this.problemForm.problem.problemResolutionType = problemResolutionTypeEnum.notResolvedYet.value
      this.problemForm.problem.damageType = 'none'
      this.problemForm.item.action = ItemActionTypeEnum.fba.value
      this.problemForm.item.itemCondition = conditionTypeEnum.NEW.key
      this.problemForm.item.warehouseId = this.userWarehouseId
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
