// /**
//  * Enum for condition type.
//  * @readonly
//  * @enum {{key: string, value: string}}
//  */
// })
import find from 'lodash/find'

const conditionTypeEnum = Object.freeze({
  NEW: {
    key: 'NEW',
    amazonKey: 'new_new',
    text: 'New',
  },
  NEW_OPEN_BOX: {
    key: 'NEW_OPEN_BOX',
    amazonKey: 'new_open_box',
    text: 'New-Open Box',
  },
  NEW_OEM: {
    key: 'NEW_OEM',
    amazonKey: 'new_oem',
    text: 'New-OEM',
  },
  REFURBISHED: {
    key: 'REFURBISHED',
    amazonKey: 'refurbished_refurbished',
    text: 'Refurbished',
  },
  USED_LIKE_NEW: {
    key: 'USED_LIKE_NEW',
    amazonKey: 'used_like_new',
    text: 'Used-Like New',
  },
  USED_VERY_GOOD: {
    key: 'USED_VERY_GOOD',
    amazonKey: 'used_very_good',
    text: 'Used-Very Good',
  },
  USED_GOOD: {
    key: 'USED_GOOD',
    amazonKey: 'used_good',
    text: 'Used-Good',
  },
  USED_ACCEPTABLE: {
    key: 'USED_ACCEPTABLE',
    amazonKey: 'used_acceptable',
    text: 'Used-Acceptable',
  },
  COLLECTIBLE_LIKE_NEW: {
    key: 'COLLECTIBLE_LIKE_NEW',
    amazonKey: 'collectible_like_new',
    text: 'Collectible-Like New',
  },
  COLLECTIBLE_VERY_GOOD: {
    key: 'COLLECTIBLE_VERY_GOOD',
    amazonKey: 'collectible_very_good',
    text: 'Collectible-Very Good',
  },
  COLLECTIBLE_GOOD: {
    key: 'COLLECTIBLE_GOOD',
    amazonKey: 'collectible_good',
    text: 'Collectible-Good',
  },
  COLLECTIBLE_ACCEPTABLE: {
    key: 'COLLECTIBLE_ACCEPTABLE',
    amazonKey: 'collectible_acceptable',
    text: 'Collectible-Acceptable',
  },
  CLUB: {
    key: 'CLUB',
    amazonKey: 'club_club',
    text: 'Club',
  },
})

function getAmazonKey(keyItem) {
  const options = []
  Object.keys(conditionTypeEnum).map(key => options.push({ value: conditionTypeEnum[key].key, amz: conditionTypeEnum[key].amazonKey }))
  const local = find(options, p => (p.amz === keyItem || p.value === keyItem))
  return local ? local.amz : keyItem
}

function getLocalKey(amazonKey) {
  const options = []
  Object.keys(conditionTypeEnum).map(key => options.push({ value: conditionTypeEnum[key].key, amz: conditionTypeEnum[key].amazonKey }))
  const local = find(options, p => p.amz === amazonKey)
  return local ? local.value : amazonKey
}

function getLocalText(predicate, predicateKey = 'key') {
  const result = find(conditionTypeEnum, { [predicateKey]: predicate })
  return result ? result.text.toUpperCase() : ''
}

function getConditionTypeEnumList() {
  const options = []
  Object.keys(conditionTypeEnum).map(key => options.push({ value: conditionTypeEnum[key].key, text: conditionTypeEnum[key].text }))
  return options
}

export default conditionTypeEnum
export {
  getAmazonKey,
  getLocalKey,
  getLocalText,
  getConditionTypeEnumList,
}
