import { render, staticRenderFns } from "./ProblemForm.vue?vue&type=template&id=af6b2102&scoped=true"
import script from "./ProblemForm.vue?vue&type=script&lang=js"
export * from "./ProblemForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af6b2102",
  null
  
)

export default component.exports