import find from 'lodash/find'
/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const yesNoEnum = Object.freeze({
  YES: { key: 'YES', value: 'YES', text: 'Yes' },
  NO: { key: 'NO', value: 'NO', text: 'No' },
})

function getYesNoEnum(predicate, predicateKey = 'key') {
  const result = find(yesNoEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getYesNoEnumList() {
  const options = []
  Object
    .keys(yesNoEnum)
    .map(key => options.push(
      {
        value: yesNoEnum[key].value,
        text: yesNoEnum[key].text,
      },
    ))
  return options
}

export default yesNoEnum

export {
  getYesNoEnum,
  getYesNoEnumList,
}
